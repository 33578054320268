<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>

          <CRow>
              <CCol md="3">
                    <h4>Advertisement</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />
              </CCol>
              <CCol md="2">
                <div class="form-group">
                    <b-form-select @change="statusChange" v-model="status" :options="optStatus"></b-form-select>
                </div>
              </CCol>
              <CCol md="2">
                 <b-button variant="primary" to="/advertise/create" >New Ads</b-button>
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody>
         <CRow>
            <CCol col="12" class="text-left">
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol>
         </CRow>

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>

          <!-- <CRow>
                 <b-col sm="6">
                        <b-form-checkbox
                          id="cbstatus"
                          v-model="cbstatus"
                          name="cbstatus"
                          @change="onModifyClick"
                        >
                         Eligible List
                        </b-form-checkbox>
                        </b-col>
            </CRow><br/>                 -->

        <div class="table-responsive scroll-r">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="appearanceOrder" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

              <template v-slot:cell(expiryDate)="{ item }">
                {{ item.expiryDate | dateWithTime }}
              </template>

              <template v-slot:cell(limitedToUserGroups)="{ item }">
                {{ item.limitedToUserGroups | getlabel }}
              </template>

              <template v-slot:cell(limitedToUsers)="{ item }">
                {{ item.limitedToUsers | getlabel }}
              </template>

              <template v-slot:cell(limitedToNationality)="{ item }">
                {{ item.limitedToNationality | getlabel }}
              </template>

              <template v-slot:cell(allowedNationalities)="{ item }">
                {{ item.allowedNationalities | getNationlabel }}
              </template>

              <template v-slot:cell(actions)="{ item }">
                <b-dropdown variant="info" text="Actions" size="md">
                  <router-link :to='"/advertise/edit/" + item.id' tag="b-dropdown-item" > Edit </router-link>
                  <router-link :to='"/advertise/image/" + item.id' tag="b-dropdown-item" > Image </router-link>
                </b-dropdown>
              </template>

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>
        </div>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AdvertiseService from '@/api/AdvertiseService.js';
import listOfNation from '@/assets/nationalities.json';

 export default {
    name: "ads",
    components: {
    },
    data() {
      return {
        cbstatus : false,
        spinner:false,
        optStatus: [
            { value: 'ACTIVE', text: 'ACTIVE'},
            { value: 'INACTIVE', text: 'INACTIVE'}
        ],
        status:'ACTIVE',
        list:[],
        columns : [],
        data : [],
        items:[],
        fields: [
          {
            key: 'appearanceOrder',
            label : 'Appear Order',
            sortable: true
          },
          {
            key: 'name',
            label : 'Ads Name',
            sortable: true
          },
          {
            key: 'title',
            label : 'Title',
            sortable: true
          },
          {
            key: 'body',
            label : 'Body',
            sortable: true
          },
          {
            key: 'adType',
            label : 'AD Type',
            sortable: true
          },
          {
            key: 'limitedToUserGroups',
            label : 'Limited User Group',
            sortable: true
          },
          {
            key: 'allowedUserGroups',
            label : 'Allow User Group',
            sortable: true
          },
          {
            key: 'limitedToUsers',
            label : 'Limited Users',
            sortable: true
          },
          {
            key: 'allowedUsers',
            label : 'Allow Users',
            sortable: true
          },
          {
            key: 'limitedToNationality',
            label : 'Limited Nationality',
            sortable: true
          },
          {
            key: 'allowedNationalities',
            label : 'Allow Nationality',
            sortable: true
          },
          {
            key: 'termsAndConditions',
            label : 'T & C',
            sortable: true
          },
          {
            key: 'expiryDate',
            label : 'Expiry Date',
            sortable: true
          },
          {
            key: 'recordStatus',
            label : 'Status',
            sortable: true
          },
          {
            key: 'actions',
            label: 'Actions'
          }
        ],
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:''
      };
    },
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;
        },
        formatNum(value){
           if(!value) return 0
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        },
        getlabel(value){
          return (value === true) ? "Yes" : "No";
        },
        getNationlabel(value){
          if(!value) return ""
          var list =[];
          value.forEach(function(natkey){
             list.push(listOfNation[natkey])
          });
          return list.join(", ");
        },

    },

    created () {
      this.getAdvertisementList(this.status);
    },
    methods: {

      onModifyClick(){
          this.spinner = true;

          if(this.cbstatus === true){
            this.getEligibleAdsList();
          } else {
            this.getAdvertisementList();
          }

      },

      getFilterData(){
         this.data  = this.list;
      },

      ActionDelete(id){
          var result = confirm("Are you sure want to Delete Template ?");
          if (result) { this.deleteNotifTemplate(id); }
      },

      statusChange(){
        this.getAdvertisementList(this.status);
      },

      deleteNotifTemplate: function(id) {
        AccountService.deleteNotifTemplate(id).then(resp => {  //console.log(resp);
            if(resp.message==="Success"){
              alert("Delete Template Success!"); this.getdNotifTemplateList();
            } else {
              this.msg   =  !resp.data.message ? "Delete Template Failed!" : resp.data.message;
              this.color = 'danger'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000);
            }

        }, error => {
          this.loading = false;
        });
      },

      getAdvertisementList: function(status) {
         AdvertiseService.getAdvertisementList(status).then(resp => { //console.log(resp);
            this.items = resp; this.spinner = false;
         }, error => { this.spinner = false; });
      }

    }
 };

</script>

<style>
    .scroll-r{ overflow-y:scroll; }
</style>
